.home-page__catalog {
  margin-bottom: 104px;
}
.catalog__heading {
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px;
}
.btn_color_still-1 {
  background-image: linear-gradient(to bottom right, #9d7e13, #b3983f, #c9b365, #dfce8a, #f6eab1);
}
.btn_color_still-1 {
  background-color: rgba(74, 152, 223, 0.25);
  border-color: #ffffff;
}
.btn_color_still {
  border-style: solid;
  border-width: 2px;
  &:hover {
    border-color: #9d7e13;
  }
}

.catalog__slide:not(:last-child) {
  margin-bottom: 30px;
}
.catalog__slide {
  display: grid;
  grid-template-areas:
    "img img img img head head head head head head head head"
    "img img img img ss ss ss ss ss ss ss ss"
    "img img img img more more more more more more more more"
    "img img img img more more more more more more more more";
  display: flex;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(4, auto);
  grid-column-gap: 30px;
  padding-top: 51px;
  padding-bottom: 31px;
}
.content-block {
  position: relative;
  display: grid;
  border-radius: 17px;
  background-color: #ffffff;
  @include media-1200 {
    padding-right: 31px;
    padding-left: 31px;
  }
}
.catalog__header {
  grid-area: head;
  padding-bottom: 26px;
}
.link-nounderline {
  text-decoration: none;
}
.catalog__subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 113.5%;
  letter-spacing: -0.005em;
  color: #3f4260;
}
.catalog__title {
  margin-bottom: 7px;
}
.catalog__img-wrap {
  grid-area: img;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  /* padding-top: 39px; */
  z-index: 1;
}

.catalog__img {
  -o-object-fit: contain;
  object-fit: contain;
  height: 100%;
  width: 100%;
  max-width: 324px;
  max-height: 284px;
  z-index: 1;
}
.catalog__subslider-wrap {
  grid-area: ss;
  padding-bottom: 21px;
  min-height: 135px;
}
.catalog__subslider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-right: 17px;
}
.catalog__subslide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 10px;
  width: 33.3333333333%;
}
.catalog__subslide-img {
  width: 103px;
  height: 103px;
  margin-bottom: 10px;
}
.catalog__subslide-text {
  text-align: center;
  font-family: "RotondaC", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 166%;
  color: #3f4260;
}
.catalog__more {
  grid-area: more;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-right: 50px;
}
.catalog__more-button {
  padding-top: 15px;
  padding-bottom: 15px;
}

.btn_color_bright-1 {
  color: black;
  background-color: #fff200;
  -webkit-box-shadow:
    0 2px 0 #fff200,
    0 21px 18px -7px rgba(233, 231, 84, 0.29);
  box-shadow:
    0 2px 0 #fff200,
    0 21px 18px -7px rgba(233, 231, 84, 0.29);
}
@include media-1200 {
  .catalog__title {
    margin-bottom: 12px;
    font-size: 25px;
    line-height: 105%;
  }
  .catalog__subtitle {
    font-size: 16px;
  }
  .catalog__subslide-img {
    width: 70px;
    height: 70px;
    margin-bottom: 3px;
  }
  .catalog__subslide-text {
    font-size: 13px;
    line-height: 160%;
  }
  .catalog__img-wrap {
    padding-top: 35px;
    padding-bottom: 26px;
  }
  .catalog__header {
    padding-bottom: 14px;
  }
  .catalog__slide {
    padding-top: 33px;
    padding-bottom: 31px;
    padding-left: 14px;
    padding-right: 14px;
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 26px;
    grid-template-areas:
      "img img img img head head head head head head head head"
      "img img img img ss ss ss ss ss ss ss ss"
      "img img img img ss ss ss ss ss ss ss ss"
      "img img img img more more more more more more more more"
      "img img img img more more more more more more more more";
  }
  .catalog__subslide {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .catalog__more {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-right: 0;
  }
}
@include media-768 {
  .catalog__slider {
    padding-bottom: 0;
  }
  .catalog__slide {
    display: block;
    padding-top: 53px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .catalog__header {
    padding-bottom: 12px;
  }
  .catalog__title {
    font-size: 28px;
  }
  .catalog__img-wrap {
    padding-top: 0;
    padding-bottom: 40px;
    padding-right: 8px;
    padding-left: 8px;
  }
  .catalog__img {
    max-width: 234px;
  }
  .catalog__subslider-wrap {
    padding-bottom: 71px;
  }
  .catalog__subslider {
    display: block;
    margin-bottom: 0 !important;
  }
  .catalog__subslide {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    padding-right: 0;
  }
  .catalog__subslide-img {
    margin-bottom: 0;
    margin-right: 9px;
  }
  .catalog__subslide-text {
    padding-right: 6px;
    text-align: left;
    line-height: 139%;
  }
  .catalog__heading {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .catalog__main-header {
    padding-bottom: 25px;
  }
  .catalog__full {
    width: 100%;
  }
}
