.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  max-width: 1320px;

  // @include media-min-width(1400px) {
  //   max-width: 1320px;
  // }
  // @include media-min-width(1200px) {
  //   max-width: 1140px;
  // }
  // @include media-min-width(992px) {
  //   max-width: 960px;
  // }
  // @include media-min-width(768px) {
  //   max-width: 720px;
  // }
  // @include media-min-width(576px) {
  //   max-width: 540px;
  // }
  @include media-1400 {
    max-width: 1320px;
  }

  @include media-1320 {
    max-width: 1200px;
  }
  @include media-1200 {
    max-width: 1080px;
  }
  @include media-1080 {
    max-width: 992px;
  }
  @include media-992 {
    max-width: 720px;
  }
  @include media-768 {
    max-width: 576px;
  }
}

.error {
  padding-bottom: 150px;
}
.centered,
p.large.centered {
  text-align: center;
}
.content {
  padding: 65px 0 65px 0;
  position: relative;
}
.text-block-narrow {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
