$font: "Roboto", sans-serif;

$c-black: #000;
$c-white: #fff;

@mixin media-min-width($min-width) {
  @media (min-width: #{$min-width}) {
    @content;
  }
}
@mixin media-1400 {
  @media (max-width: 1400px) {
    @content;
  }
}

@mixin media-1320 {
  @media (max-width: 1320px) {
    @content;
  }
}

@mixin media-1200 {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin media-1080 {
  @media (max-width: 1080px) {
    @content;
  }
}

@mixin media-992 {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin media-768 {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin media-576 {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin media-480 {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin media-360 {
  @media (max-width: 360px) {
    @content;
  }
}
