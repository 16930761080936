.footer {
  background-color: #fff200;
  min-height: 260px;
  padding-top: 62px;
  padding-bottom: 62px;
}
.footer__inner {
  position: relative;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.col-md-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-md-3 {
  flex: 0 0 auto;
  width: 25%;
}
.footer__logo-wrap {
  position: relative;
  display: block;
  height: 100%;
}
.footer__logo {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  // width: 155px;
  height: 88px;
}
.footer__social-wrap {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.footer__social-set {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.footer__social-title {
  display: block;
  font-family: 'RotondaC', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 147.69%;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 28px;
  width: 100%;
}
.footer__social {
  margin-bottom: 30px;
}
.footer .social__title {
  font-family: 'RotondaC', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #74a3da;
}
.social__set {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.social__icon-wrap:not(:last-child) {
  margin-right: 12px;
}

.social__icon-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #3090e2;
  cursor: pointer;
  &:hover {
    background-color: #0164ba;
  }
}
.social__icon {
  width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.col-md-6 {
  flex: 0 0 auto;
  width: 50%;
}
.footer__menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.text-decoration-none {
  text-decoration: none !important;
}
.d-block {
  display: block !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-lg-0 {
  margin-top: 0 !important;
}
.col-md-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.footer__warning {
  padding-top: 53px;
  margin-bottom: 35px;
}
.footer__warning p,
.footer__copyright p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 147.69%;
  letter-spacing: 0.02em;
  color: #74a3da;
  margin: 0;
}
.mt-3 {
  margin-top: 1rem !important;
}
@include media-1200 {
  .footer {
    padding-bottom: 42px;
  }
}
@include media-768 {
  .footer {
    padding-top: 30px;
  }
  .footer__social-wrap {
    position: static;
  }
  .footer__logo-wrap {
    padding-bottom: 27px;
  }
  .row {
    flex-direction: column;
  }
  .footer__logo {
    position: static;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    height: 105px;
  }
  .footer__menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 0;
    margin-bottom: 25px;
  }
  .col-6 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .footer__inner {
    .row > * {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      margin-top: var(--bs-gutter-y);
    }
  }
}
