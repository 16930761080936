.articles-page__header {
  margin-bottom: 70px;
}
.bg-white {
  background-color: #fff !important;
}

.p-4 {
  padding: 1.5rem !important;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
.card-body p {
  margin-bottom: 1rem !important;
}
.partner__img {
  width: 400px;
  height: 300px;
  @include media-480 {
    width: auto;
    height: auto;
  }
}
.partner__box {
  display: flex;
  position: relative;
}
img,
svg {
  vertical-align: middle;
}

img {
  vertical-align: middle;
  border-style: none;
}
.partner__info {
  position: absolute;
  width: 64%;
  right: 20px;
}
