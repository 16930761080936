.home-page__quest {
  margin-bottom: 80px;
}
.home-quest__header {
  margin-bottom: 40px;
}
.home-quest__slider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.home-quest__slide {
  position: relative;
  width: 369px;
  padding-top: 34px;
}
.home-quest__slide-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 114px;
  height: 114px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 7px;
  border-style: solid;
  border-color: #ffffff;
}
.home-quest__slide-text {
  background-color: #5bc9f9;
  border-radius: 17px;
  padding: 19px 24px;
  height: 187px;
}
.home-quest__slide-title {
  padding-left: 108px;
  margin-bottom: 27px;
  font-style: normal;
  font-weight: 900;
  font-size: 23px;
  line-height: 103%;
  color: #ffffff;
  font-family: "Roboto" sans-serif;
}
.home-quest__slide-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 151%;
  color: #ffffff;
  font-family: "Roboto" sans-serif;
}
@include media-1200 {
  .home-page__quest {
    margin-bottom: 85px;
  }
  .home-quest__header {
    margin-bottom: 53px;
  }
}
@include media-992 {
  .home-quest__slider {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
}
@include media-768 {
  .home-quest__header {
    margin-bottom: 30px;
  }
  .home-quest__slider {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-quest__slide {
    width: 100%;
  }
}
