.catalog-faq {
    padding-bottom: 57px;
}
.catalog-faq__heading {
    margin-bottom: 42px;
    max-width: 600px;
}
.catalog__slider-box {
    padding: 0 15px;
}
.catalog-faq__slide {
    display: block;
    border-radius: 14px;
    height: 390px;
    margin-bottom: 0;
    background-color: rgba(255, 255, 255, 0.28);
    background-image: url("../img/faq-back.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    outline: none;
    cursor: pointer;
    -webkit-perspective: 5000px;
    perspective: 5000px;
}
input[type="checkbox"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

.catalog-faq__slide-checkbox {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
}
.catalog-faq__slide-inner {
    position: relative;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    height: 100%;
}
.catalog-faq__question p {
    font-family: "RotondaC", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #3f4260;
    margin-bottom: 0;
    padding: 25px;
}
.catalog-faq__question {
    position: relative;
    top: 50%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 2;
    -webkit-transform: rotateY(0deg) translate(0, -50%);
    transform: rotateY(0deg) translate(0, -50%);
    transition: all 400ms;
    &--rotate {
        -webkit-transform: rotateY(180deg) translate(0, -50%);
        transform: rotateY(180deg) translate(0, -50%);
    }
}
.catalog-faq__answer {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    height: 100%;
    border-radius: 14px;
    padding: 25px 0;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 400ms;
    &--rotate {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
}

.catalog-faq__answer-text {
    font-family: "RotondaC", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 147.69%;
    letter-spacing: 0.02em;
    color: #3f4260;
    padding: 0 25px;
    margin-bottom: 0;
    height: 100%;
    overflow: auto;
    border-radius: 14px;
    scrollbar-width: thin;
    scrollbar-color: #39acff #ffffff;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #39acff;
        border-radius: 14px;
    }
}

.slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.catalog-faq__slider {
    margin-bottom: 0 !important;
    margin-left: -15px;
    margin-right: -15px;
}
.calalog__slider {
    display: flex;
    gap: 15px;
}
@include media-1400 {
    .catalog-faq__slider .slick-next {
        right: 25%;
        top: 110%;
    }
    .catalog-faq__slider .slick-prev {
        left: 25%;
        top: 110%;
    }
    .catalog-faq__slider .slick-dots {
        bottom: -50px;
    }
}
@include media-768 {
    .catalog-faq__slider .slick-next {
        right: 0;
        top: 105%;
    }
    .catalog-faq__slider .slick-prev {
        left: 0;
        top: 105%;
    }
    .catalog-faq__slider .slick-dots {
        bottom: -50px;
    }
    .catalog-faq {
        margin-bottom: 40px;
    }
    .catalog-faq__slide {
        margin-bottom: 10px;
    }
}
