.home-page__banner {
    margin-bottom: 80px;
    @include media-768 {
        margin-bottom: 100px;
    }
}
.home-banner__slider {
    border-radius: 17px;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    -webkit-box-shadow: 0 22px 41px rgba(0, 0, 0, 0.5);
    box-shadow: 0 22px 41px rgba(0, 0, 0, 0.5);
    line-height: 0;
}
.home-banner img.home-banner__img {
    width: 100%;
    height: 100% !important;
    border-radius: 17px;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-dots li button:before {
    font-size: 10px;
    opacity: 0.2;
    color: #fff;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #fff;
}
.home-banner__slide {
    position: relative;
    -o-object-fit: cover;
    object-fit: cover;
}
.slick-slide div {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}
@include media-768 {
    .home-banner__slider .slick-next {
        right: 0;
        top: 107%;
    }
    .home-banner__slider .slick-prev {
        left: 0;
        top: 107%;
    }
    @include media-768 {
        .slick-dots {
            bottom: -45px;
        }
    }
}
