.video-responsive {
  width: 30%;
  height: 300px;
  margin: 0 20px;
  @include media-992 {
    width: 45%;
    margin: 0 10px;
  }
  @include media-768 {
    width: 100%;
    margin: 0;
  }
  @include media-480 {
    height: 250px;
  }
}
