.catalog-products__slide:not(:last-child) {
    margin-bottom: 34px;
}
.catalog-products__slide {
    display: grid;
    grid-template-areas:
        "img img img img head head head head head stars stars stars"
        "img img img img ss ss ss ss ss ss ss ss"
        "img img img img sizes sizes sizes sizes sizes more more more"
        "img img img img note note note note note more more more";
    grid-column-gap: 30px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4, auto);
    padding-top: 51px;
    padding-bottom: 20px;
    outline: none;
    @include media-1200 {
        padding-top: 42px;
        padding-bottom: 40px;
        grid-template-rows: repeat(5, auto);
        grid-column-gap: 26px;
        grid-template-areas:
            "img img img img img head head head head head head head"
            "img img img img img ss ss ss ss ss ss ss"
            "stars stars stars stars stars ss ss ss ss ss ss ss"
            "sizes sizes sizes sizes sizes note note note note note note note"
            "sizes sizes sizes sizes sizes more more more more more more more";
    }
}
.catalog-products__header {
    grid-area: head;
    padding-bottom: 37px;
}
.link-nounderline {
    text-decoration: none;
}
.catalog-products__title {
    margin-bottom: 10px;
}
.catalog-products__img-wrap {
    grid-area: img;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1;
}
.catalog-products__img {
    -o-object-fit: contain;
    object-fit: contain;
    height: 100%;
    width: 100%;
    max-width: 324px;
    max-height: 360px;
}
.catalog-products__subslider-wrap {
    grid-area: ss;
    padding-bottom: 10px;
    min-height: 135px;
}
.catalog-products__sizes {
    grid-area: sizes;
    padding-bottom: 22px;
    width: 100%;
}
.diapers-sizes {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.diapers-sizes__header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 65px;
    line-height: 210.19%;
    font-family: "RotondaC", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #3f4260;
    opacity: 0.5;
}
.diapers-sizes__tile:not(:last-child) {
    margin-right: 5px;
}

.diapers-sizes__tile {
    font-family: "RotondaC", sans-serif;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 51px;
    height: 72px;
    background-color: rgba(23, 112, 217, 0.07);
    border-radius: 15px;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 197.69%;
    text-decoration: none;
    cursor: pointer;
}
.diapers-sizes__tile:hover,
.diapers-sizes__tile_active {
    background-color: #f9d3e4;
    border: 2px solid #e95498;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: -1px 10px 8px rgba(233, 84, 152, 0.15);
    box-shadow: -1px 10px 8px rgba(233, 84, 152, 0.15);
    text-decoration: none;
}
.catalog-products__note {
    grid-area: note;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    font-family: "RotondaC", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 141.19%;
    color: #3f4260;
}
.catalog-products__note-second {
    line-height: 210.19%;
    letter-spacing: 0.02em;
    opacity: 0.5;
}
.diapers-sizes__size {
    font-weight: 700;
    color: #1770d9;
}
.diapers-sizes__weight {
    font-weight: 400;
    color: #3f4260;
}
@include media-1200 {
    .catalog-products__header {
        padding-bottom: 25px;
    }
    .catalog-products__img-wrap {
        padding-top: 35px;
        padding-bottom: 26px;
    }
    .catalog-products__subslider-wrap {
        padding-bottom: 29px;
    }
    .catalog-products__sizes {
        padding-bottom: 0;
    }
}
@include media-768 {
    .catalog-products__slide {
        display: block;
        padding-top: 53px;
        padding-bottom: 20px;
    }
}
