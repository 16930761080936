.home-about {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
}
.home-about__text-content {
  margin-right: 42px;
}
.home-about__title {
  margin-bottom: 23px;
}
.home-about__article {
  margin-bottom: 23px;
  font-family: "RotondaC", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 151%;
  color: #3f4260;
}
.home-about__btn {
  width: 156px;
}
.btn_color_still-2 {
  background-color: #ffffff;
  color: #5bc9f9;
  border-color: #5bc9f9;
}
.home-about__img {
  height: 347px;
  min-width: 381px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://www.sofgigienik.com/template/sofgigienik/content/home/about.png");
  border-radius: 21px;
  -webkit-box-shadow: 0 22px 41px rgba(23, 112, 217, 0.24);
  box-shadow: 0 22px 41px rgba(23, 112, 217, 0.24);
}
.about-page {
  margin-bottom: 84px;
}
.about-page__header {
  margin-bottom: 50px;
}
.about-article_bg_light {
  background-color: #ffffff;
}

.about-article_1 {
  min-height: 200px;
}
.about-article {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  border-radius: 17px;
  -webkit-box-shadow: 0 22px 41px rgba(23, 112, 217, 0.24);
  box-shadow: 0 22px 41px rgba(23, 112, 217, 0.24);
}
.about-article__content {
  z-index: 1;
  min-width: 100%;
  padding: 32px 40px;
}
.about-article_1 .about-article__header {
  max-width: 500px;
}

.about-article__header {
  padding-bottom: 42px;
  line-height: 122%;
}
.about-article_1 .about-article__text {
  width: 50%;
  padding-right: 80px;
}

.about-article__text {
  font-family: "RotondaC", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 141.19%;
  color: #3f4260;
}
.w-100 {
  width: 100% !important;
}
.about-article__text p {
  margin-bottom: 0;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.about__img {
  width: 50%;
  @include media-992 {
    width: 100%;
  }
}
@include media-1200 {
  .home-about {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-top: 80px;
    padding-bottom: 49px;
  }
  .home-about__text-content {
    margin-bottom: 34px;
  }
  .home-about__title {
    margin-bottom: 30px;
  }
  .home-about__img {
    height: 330px;
    min-width: 100%;
  }
  .about-article_1 .about-article__text {
    padding-right: 55px;
  }
}
@include media-768 {
  .home-about {
    padding-top: 47px;
    padding-bottom: 36px;
  }
  .home-about__text-content {
    margin-right: 0;
    margin-bottom: 50px;
  }
  .home-about__img {
    height: 320px;
    background-position: center;
  }
  .about-article_1 .about-article__text {
    padding-right: 0;
    width: 100%;
  }
  .about-article__header {
    padding-bottom: 30px;
  }
}
