.arrow-icon {
    width: 50px; // Adjust the icon size as needed
    height: 50px;
    transition: color 0.3s ease; // Smoothly transition the color change
    color: #000000; // Initial icon color
    background-color: #fff;
    border-radius: 50%;
    &:hover {
        background-color: #fff;
    }

    &::before {
        content: "";
        position: absolute;
        transform: rotate(45deg);
        width: 20px;
        height: 20px;
        top: 15px;
        left: 10px;
        border-right: 2px solid #000; // Black color for the arrow
        border-top: 2px solid #000;
    }
}
.arrow-icon-prev {
    width: 50px; // Adjust the icon size as needed
    height: 50px;
    transition: color 0.3s ease; // Smoothly transition the color change
    color: #000000; // Initial icon color
    background-color: #fff;
    border-radius: 50%;
    z-index: 20;
    &:hover {
        background-color: #fff;
    }

    &::before {
        content: "";
        position: absolute;
        transform: rotate(225deg);
        width: 20px;
        height: 20px;
        top: 15px;
        left: 20px;
        border-right: 2px solid #000; // Black color for the arrow
        border-top: 2px solid #000;
    }
}
