.header {
  background-color: #fff200;
  padding-top: 18px;
  padding-bottom: 47px;
  height: 270px;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.header {
  background-image: url("../img/header-bg.png");
  background-position: center;
  background-size: cover;
}
.header__logo {
  height: 97px;
  margin-right: 29px;
}
.header__content {
  background-color: transparent;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.header__nav {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @include media-1200 {
    display: none;
    opacity: 0;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: translate(0, 100%);
    -ms-transform: translate(0, 100%);
    transform: translate(0, 100%);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 38px;
    padding-left: 20px;
  }
}
.header__burger {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.burger {
  display: none;
  width: 30px;
  height: 25px;
  background-color: transparent;
  border: none;
  background-image: url("../img/burger.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}
.burger-close {
  display: none;
  width: 22px;
  height: 22px;
  background-color: transparent;
  border: none;
  background-image: url("../img/close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}
.header__nav-item:not(:last-child) {
  margin-right: 27px;
}

.header__nav-item {
  font-size: 16px;
  line-height: 147.69%;
  color: black;
  &:hover {
    color: blue;
  }
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.social__title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 147%;
  text-align: left;
  color: #000000;
  opacity: 1;
  margin-bottom: 7px;
}
.mt-lg-0 {
  margin-top: 0 !important;
}
.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
}
.fw-normal {
  font-weight: 400 !important;
}

.p-0 {
  padding: 0 !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.m-0 {
  margin: 0 !important;
}
.dropdown-toggle {
  white-space: nowrap;
}
.d-inline {
  display: inline !important;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  // display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  &:hover {
    text-decoration: underline;
    background-color: #dfdfdf;
  }
}

.social__icon_fb {
  background-image: url("../img/facebook.svg");
}
.social__icon_insta {
  background-image: url("../img/instagram.svg");
}
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
  color: #fff;
}
@include media-1200 {
  .header__nav_visible {
    opacity: 1;
  }
  .header__nav_display {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .burger-close_visible {
    display: block;
  }
  .burger {
    display: block;
  }
  .header__social {
    margin-right: 70px;
  }
  .burger_hidden {
    display: none;
  }
  .header__nav-item:not(:last-child) {
    margin-bottom: 17px;
  }
}
@include media-768 {
  .header__social {
    display: none;
  }
  .header {
    background-color: #fff200;
    padding-top: 18px;
    padding-bottom: 47px;
    height: 162px;
    padding-right: 50px;
    padding-left: 50px;
  }
}
