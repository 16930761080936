.catalog-page {
    padding-bottom: 144px;
}
.catalog-products {
    position: relative;
}

.catalog-page__products {
    margin-bottom: 100px;
}
