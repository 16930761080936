@import "./_var";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}
body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.4;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.back-box {
  background:
    url("../img/right-pattern.svg") right top repeat-y scroll,
    url("../img/left-pattern.svg") left top repeat-y scroll,
    linear-gradient(to right, #fdf35e 50.5%, #fdf35e 50%) no-repeat;
  background-size: auto;
  min-height: 100vh;
  background-color: #fdf35e;
  @include media-1200 {
    background: -o-linear-gradient(to right, #fdf35e 50%, #fdf35e 50%) no-repeat;
    background: linear-gradient(to right, #fdf35e 50%, #fdf35e 50%) no-repeat;
  }
}
a {
  color: #0d6efd;
  text-decoration: none;
  cursor: pointer;
}

img {
  max-width: 100%;
}
p {
  margin-bottom: 0 !important;
}
ul {
  list-style-type: none;
  list-style-position: inside;
}

// styles
@import "./helper.scss";
@import "./layout.scss";
@import "./header.scss";
@import "./quest.scss";
@import "./catalog.scss";
@import "./slider.scss";
@import "./banner.scss";
@import "./faq.scss";
@import "./videos.scss";
@import "./article.scss";
@import "./about.scss";
@import "./footer.scss";
@import "./catalog-page.scss";
@import "./productbox.scss";
@import "./sales.scss";
@import "./partners.scss";
