.discount-page {
    padding-bottom: 84px;
}
.discount-filters__dashboard {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.h1 {
    font-family: "RoundsBlack", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 55px;
    line-height: 105%;
    letter-spacing: 0.02em;
    color: #3f4260;
    padding: 0;
    margin-bottom: 0;
}
.discount-filters__header {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 60px;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.row-box {
    width: 200px;
}
.row-box:not(:last-child) {
    margin-right: 15px;
}
.discount-filters__slide {
    height: 301px;
}

.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
}
.discount-filters__slide_img {
    width: 100%;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}
.sale-box {
    padding: 3px;
    transition: all 400ms ease-in;
    &:hover {
        transform: scale(1.3);
        z-index: 1000;
        -webkit-box-shadow: 4px 4px 16px 3px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 4px 4px 16px 3px rgba(34, 60, 80, 0.2);
        box-shadow: 4px 4px 16px 3px rgba(34, 60, 80, 0.2);
    }
}

.bg-white {
    background-color: #fff !important;
}
.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}
.text-dark {
    color: #212529 !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}
.d-inline {
    display: inline !important;
}
@include media-768 {
    .sales__flex {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    .row-box {
        width: 100%;
    }
}
