.home-page__articles {
  padding-bottom: 62px;
  background:
    url("../img/pattern.svg") center no-repeat,
    #fdf35e;
  background-size: cover;
}
.articles {
  padding-top: 100px;
  padding-bottom: 16px;
}
.articles__heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 50px;
}
.articles__slider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0 !important;
  margin-left: -15px;
  margin-right: -15px;
}
.articles__slide {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  max-height: 532px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 40px;
  overflow: hidden;
}
.articles__slide-img-wrap {
  height: 261px;
  margin-bottom: 17px;
  -webkit-box-shadow: 0 22px 23px -4px rgba(23, 112, 217, 0.24);
  box-shadow: 0 22px 23px -4px rgba(23, 112, 217, 0.24);
  border-radius: 17px;
}
.articles__slide-img {
  border-radius: 17px;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.articles__slide-link {
  position: relative;
  display: inline-block;
  padding: 8px 33px 8px 15px;
  background: rgba(0, 80, 200, 0.47);
  border-radius: 17px;
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 31px;
  letter-spacing: -0.005em;
  color: #ffffff;
  margin-bottom: 10px;
  &:hover {
    color: #000000;
  }
}
.articles__slide-link_pink {
  background-color: #e95498;
}
.articles__slide-link_green {
  background-color: #2dc37b;
}
.articles__slide-link_orange {
  background-color: #ff9736;
}
.articles__slide-link:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 13px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 8px;
  height: 13px;
  // background-image: url('');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.articles__slide-text p {
  font-family: "RotondaC", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 147.69%;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 0;
  max-height: 197px;
  overflow: hidden;
  -webkit-line-clamp: 8;
}
.subscribe__inner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-shadow: 0 22px 41px rgba(23, 112, 217, 0.24);
  box-shadow: 0 22px 41px rgba(23, 112, 217, 0.24);
  border-radius: 17px;
  background-color: #ffffff;
  padding-top: 38px;
  padding-bottom: 38px;
  overflow: hidden;
}
.subscribe__letter {
  position: absolute;
  right: -29px;
  bottom: -25px;
  z-index: 0;
  width: 157px;
}
.subscribe__heading {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-left: 36px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.subscribe__icon {
  width: 48px;
  margin-right: 24px;
}
.subscribe__title {
  font-family: "RotondaC", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 31px;
  letter-spacing: -0.005em;
  color: #3f4260;
}
.subscribe__subtitle {
  font-family: "RotondaC", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 147.69%;
  letter-spacing: 0.02em;
  color: #3f4260;
}
.subscribe__form {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
  z-index: 1;
}
.subscribe__form-email {
  width: 270px;
  margin-right: 30px;
}
.input-text {
  color: #60689e;
  border: 1px solid #e0f0ff;
}
.input {
  padding: 17px 23px 15px;
  font-family: "RotondaC", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 21px;
  cursor: pointer;
}
@include media-1200 {
  .home-page__articles {
    background: #fdf35e;
  }
  .articles {
    padding-top: 47px;
    padding-bottom: 117px;
  }
  .articles__heading {
    margin-bottom: 51px;
  }
  .articles__slide {
    max-height: 1000px;
    margin-bottom: 0;
  }
  .articles__slide-img-wrap {
    height: 200px;
  }
  .articles__slide-text p {
    max-height: 220px;
  }
  .subscribe__letter {
    top: auto;
    bottom: 35px;
    right: -69px;
    z-index: 0;
    width: 202px;
  }
  .subscribe__heading {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 32px;
  }
  .subscribe__form {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 100px;
  }
}
@include media-480 {
  .articles__slider {
    flex-direction: column;
  }
  .articles__slide {
    max-width: 100%;
    margin-bottom: 10px;
  }
}
@include media-768 {
  .subscribe {
    background-color: #ffffff;
  }
  .subscribe__inner {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .subscribe__letter {
    display: none;
  }
  .subscribe__heading {
    margin-bottom: 19px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .subscribe__icon {
    position: absolute;
    top: 0;
    left: 15px;
  }
  .subscribe__title {
    font-size: 23px;
    line-height: 27px;
    margin-bottom: 19px;
    padding-left: 72px;
    min-height: 39px;
  }
  .subscribe__subtitle {
    font-size: 14px;
    line-height: 151%;
  }
  .subscribe__form {
    padding-left: 15px;
  }
  .subscribe__form-email {
    margin-bottom: 16px;
    width: 100%;
  }
}
