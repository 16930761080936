.btn {
  display: inline-block;
  block-size: -webkit-fit-content;
  block-size: -moz-fit-content;
  block-size: fit-content;
  width: 170px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  border-radius: 21px;
  border: none;
  text-decoration: none;
  cursor: pointer;
}
.mt-2 {
  margin-top: 0.5rem !important;
}

.dropdown-toggle {
  white-space: nowrap;
}

section {
  position: relative;
  display: block;
}
.h2 {
  margin-bottom: 0;
  font-family: "RoundsBlack", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 105%;
  letter-spacing: 0.02em;
  text-transform: lowercase;
  color: #000000;
  text-shadow: 0 6px 41px rgba(23, 112, 217, 0.24);
}

h2,
h1 {
  /* display: inline-block; */
  background: linear-gradient(45deg, #9d7e13 33%, #b3983f 66%, #f6eab1);
  /* background: linear-gradient(45deg, #0B2349 33%, #0D61BC 66%, #8AA9D6); */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-image: linear-gradient(to bottom right, #9d7e13, #b3983f, #c9b365, #dfce8a, #f6eab1); */
  color: #fdf35e;
  border-radius: 15px;
  padding: 8px;
}

.h3 {
  font-family: "RoundsBlack", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 105%;
  letter-spacing: 0.02em;
  color: #01b0ef;
  margin-bottom: 0;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.breadcrumb {
  background-color: transparent;
  padding-bottom: 43px !important;
  font-size: 16px;
  line-height: 21px;
  color: #b7b9ca;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}
.breadcrumb__item,
.breadcrumb__item_first {
  position: relative;
  display: inline-block;
  color: #000000;
  text-decoration: none;
}

.breadcrumb__item:not(:first-child) {
  margin-left: 20px;
}
.breadcrumb__item:before {
  content: "";
  position: absolute;
  left: -15px;
  top: 50%;
  height: 80%;
  width: 2px;
  background-color: #b7b9ca;
  -webkit-transform: rotate(24deg) translate(0, -50%);
  -ms-transform: rotate(24deg) translate(0, -50%);
  transform: rotate(24deg) translate(0, -50%);
}

@include media-768 {
  .h3 {
    font-size: 23px;
  }
  .h2 {
    font-size: 28px;
  }
  .btn {
    display: block;
    width: 100%;
  }
}
